.NoticeCharge__row {
  p {
    margin: 0;
    font-size: 1.6rem;
    letter-spacing: -0.46px;
  }
}

.NoticeCharge__row-button {
  margin-top: 6rem;
  display: flex;

  .button {
    width: '100%';
    height: 6.4rem;
    margin-right: 0.9rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

.NoticeCharge__disable {
  position: absolute;
  font-size: 1.2rem;
  letter-spacing: -0.34px;
  left: -2rem;
  bottom: -5rem;
  color: #b1b3b2;
  text-decoration: underline;
  cursor: pointer;
}
