@import './fonts.scss';
@import './variables.scss';
@import './functions.scss';

@import './components/calendar/index.scss';
@import './override_antd/index.scss';

/* VARIABLES */
:root {
  --main-color: #03cf5d;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */
* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-family: 'AppleSDGothicNeo-Regular', 'Saira', sans-serif;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}
#area-dropdown-header {
  position: fixed;
  top: 0;
}
