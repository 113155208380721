.ant-table-thead > tr > th {
  background-color: initial;
  border-bottom-color: #000;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.35px;
  padding-bottom: 1rem;
}

.ant-table-thead {
  .Reservation-table__header--reservationTime,
  .Reservation-table__header--status,
  .Reservation-table__header--memo {
    text-align: center;
  }
}

.ant-table-cell {
  &.Reservation-table__header--createdAt {
    width: 7rem;
    padding-left: 0;
    padding-right: 0;
  }

  &.Reservation-table__header--username {
    width: 13rem;
    letter-spacing: -0.4px;
  }

  &.Reservation-table__header--userPhonenum {
    width: 14rem;
  }

  &.Reservation-table__header--reservationPath {
    // font-weight: 300;
  }

  &.Reservation-table__header--discountedPrice {
    width: 13rem;
  }

  &.Reservation-table__header--reservationTime {
    text-align: left;
    width: 7rem;
    padding-left: 0;
    padding-right: 0;
  }

  &.Reservation-table__header--chargePrice {
    text-align: right;
    padding-right: 2.5rem;
    width: 13rem;
    .cancel {
      text-decoration: line-through;
    }
  }

  &.Reservation-table__header--status {
    width: 12rem;
    padding: 0;
    margin: 0 1rem;
  }

  &.Reservation-table__header--memo {
    width: 17rem;
    padding-left: 0;
    cursor: pointer;
    .memo-inner {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .hasMemo {
        color: #b1b3b2;
        text-decoration: underline;
        text-decoration-color: #b1b3b2;
      }
      .normalMemo {
        text-decoration: underline;
      }
    }
    .memo-row {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.Reservation-table__header--declaration {
    width: 2rem;
    padding: 0;
    img {
      cursor: pointer;
    }
  }
}

.Reservation-table__cell {
  &--status {
    width: 100%;
    height: 4.6rem;

    .ant-select.ant-select-sm.ant-select-single.ant-select-show-arrow {
      height: 100%;
      .ant-select-selector {
        height: 100%;
      }
    }
  }

  &--memo {
    width: 100%;
    height: 4.6rem;
    &.hasMemo {
      border-color: #000;
    }
  }
}

.status-selector img {
  transition: all 0.2s ease;
}

.status-selector.ant-select-open img {
  transform: rotate(180deg);
}

.ant-table-cell {
  font-family: 'Saira';
  font-weight: 300;
  letter-spacing: 0;

  .reservationTime-text {
    cursor: pointer;
    color: #0045ac;
    transition: all 0.3s ease;
    // &:hover {
    //   color: black;
    // }
  }
}

.Reservation-popup .ant-modal-body {
  padding: 0;
  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin: 0;
  }
}

.Reservation-popup__memo {
  border: none;
  font-size: 1.6rem;
  resize: none;
  padding: 2rem;
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.Reservation-popup
  .ant-modal-content
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns {
  float: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0;
  border-top: 1px solid #e1e3e2;

  .ant-btn {
    border: none;
    padding: 0;

    span {
      color: #b1b3b2;
      text-decoration: underline;
    }

    &.ant-btn-primary {
      width: 10rem;
      height: 4.6rem;
      span {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.ant-select-arrow {
  transform: translate(-2px, -2px);
}

.ant-tooltip-inner {
  min-width: 13rem;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-center {
  margin-top: 6rem;
}
