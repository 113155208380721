.ReportUser__Row {
  display: flex;

  &--title {
    display: flex;
    flex-direction: column;
    width: 9rem;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: -0.4px;
    p {
      margin: 0;
    }
  }

  &--content {
    display: flex;
    flex: 1;
    flex-direction: column;

    input {
      padding: 1.7rem 1.4rem;
      font-size: 1.3rem;
      font-family: 'Saira';
      letter-spacing: normal;
      line-height: 1.54;
      border-radius: 4px;
    }

    textarea {
      border-radius: 4px;
      resize: none;
    }
  }

  &--content-info {
    font-size: 1.4rem;
    line-height: 1.71;
    letter-spacing: -0.4px;
  }
}
