.button-fat.ant-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.3rem 0;
  border-radius: 4px;
  color: #fff;
  background-color: #000;
  padding: 2.3rem 0;
  font-size: 1.6rem;
  font-weight: bold;
}

.button-fat__login.ant-btn {
  border-radius: 4px;
  width: 334px;
  height: 56px;
  padding: 1.8rem 1.4rem;
  margin-bottom: 1rem;
  letter-spacing: -0.6px;
  font-size: 1.3rem;
  font-weight: 600;
}

.button-fat__password.ant-btn {
  padding: 2.3rem 0;
  font-size: 1.6rem;
  font-weight: bold;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
