.RequestCharge__header {
  display: flex;

  &--top {
    margin-left: 1.5rem;

    h1 {
      letter-spacing: -0.32px;
      font-size: 2.4rem;
      margin: 0;
    }
    p {
      font-weight: bold;
      font-size: 1.2rem;
      margin: 0;
    }
  }

  &--mid {
    margin-top: 3rem;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: -0.36px;
  }

  &--bot {
    margin-top: 1rem;
    margin-bottom: 6rem;
    letter-spacing: -0.4px;
  }
}
