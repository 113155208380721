.number-positive {
  color: #0045ac;
}

.number-negative {
  color: #dd4124;
}

.ant-table-cell {
  &.ChargeTable__header {
    &--timestamp {
      padding-left: 0;
    }
    &--amount {
      text-align: right;
    }
    &--balance {
      padding-right: 0;
      text-align: right;
    }
  }
}
