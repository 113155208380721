.find-password {
  &__row {
    font-size: 1.6rem;
    letter-spacing: -0.46px;
    margin-bottom: 1rem;

    &.link {
      text-decoration: underline;
      margin-bottom: 6rem;
    }
  }
}
