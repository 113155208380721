@font-face {
  font-family: 'Saira';
  src: url('../fonts/notosanskr-regular.woff');
}

@font-face {
  font-family: 'Saira';
  src: url('../fonts/SairaSemiCondensed-Regular.ttf');
  unicode-range: U+0000-009F, U+0020-007E;
}

@font-face {
  font-family: 'SairaSemiBold';
  src: url('../fonts/SairaSemiCondensed-SemiBold.ttf');
  unicode-range: U+0000-009F, U+0020-007E;
}
