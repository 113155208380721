.app-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid #e1e3e2;
  z-index: 1000;

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 1350px;
    margin: 0 auto;
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  &__menu {
    padding-top: 1.5rem;

    &--link {
      font-size: 1.6rem;
    }

    &--item-dropdown {
      width: 24.5rem;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      padding: 0;
      cursor: pointer;
      padding: 1rem 3rem;
      &:last-child {
        margin-top: 1.5rem;
      }
    }
  }

  &__hospital {
    &--dropdown img {
      transition: all 0.2s ease;
    }
  }
}

.app-header__hospital .app-header__hospital--dropdown img {
  transform: rotate(180deg);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}

.header-info-charge {
  padding-right: 1rem;
  border-right: 1px solid #e1e3e2;
  font-weight: 600;
  font-size: 1.4rem;
}
