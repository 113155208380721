// calendar
.ant-picker-calendar
  > .ant-picker-panel
  > .ant-picker-date-panel
  > .ant-picker-body
  > .ant-picker-content
  > tbody
  > tr
  > .ant-picker-cell
  > .ant-picker-cell-inner {
  margin: 0;
}

.ant-picker-calendar-header {
  justify-content: start;
}

// selected cell, hover
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
  &:hover {
    .ant-picker-calendar-date-value {
      color: #fff;
    }
  }

  .ant-picker-calendar-date-value {
    color: #fff;
  }
}

.ant-picker-datetime-panel .ant-picker-time-panel-column {
  &::after {
    height: 200px;
  }
}

.ant-select-item-option-selected .ant-select-item-option-content {
  color: #fff;
}

.ant-layout-header .ant-menu {
  line-height: 6rem;
  border-bottom: none;
}

.ant-menu-item {
  height: 6rem;
}

.ant-menu-item-selected {
  font-weight: bold;
}

.ant-select-selector {
  height: 5.6rem;
  &:focus {
    box-shadow: none;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 5.6rem;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  color: #fff;
  background-color: #e1e3e2;
  border: none;
}

.ant-btn {
  border-radius: 4px;
}

.ant-select-dropdown {
  z-index: 999;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #fff;
  &.ant-select-item-option-active {
    background-color: #f5f5f5;
  }
  .ant-select-item-option-content {
    color: #000;
  }
}

// datepicker
// .ant-picker-cell {
//   .ant-picker-cell-inner {
//     width: 4rem;
//     height: 4rem;
//     line-height: 4rem;
//   }
// }

// .ant-picker-cell-disabled::before {
//   height: 4rem;
// }

// .ant-picker-cell-range-hover::before {
//   height: 4rem;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
//   height: 4rem;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after {
//   height: 4rem;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
//   right: 0;
// }

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #dfdfdf;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #808080;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  background-color: white;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #dfdfdf;
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.ant-select-focused .ant-select-selector,
.ant-picker-focused .ant-select-selector,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}

.ant-picker.ant-picker-range.ant-picker-focused {
  box-shadow: none;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}
