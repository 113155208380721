.InputHospitalInfo__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  &.multiple {
    margin-bottom: 0;
  }

  &--display-name {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: -0.36px;
    margin-bottom: 1rem;
  }

  &--input {
    font-family: 'Saira';
    font-weight: 300;
    letter-spacing: 0;
    border-radius: 4px;
    font-size: 1.3rem;
    line-height: 1.54;
    letter-spacing: -0.6px;
    // color: #b1b3b2;
    padding: 1.8rem 1.4rem;

    &.multiple {
      font-family: 'Saira';
      font-weight: 300;
      letter-spacing: 0;
      margin-bottom: 1rem;
    }

    &.invalid {
      border-color: red;
      &:focus {
        border-color: red;
      }
    }
  }
}

.ant-input.invalid:focus {
  border: 1px solid red;
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
}
