.ButtonSave.ant-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  height: 5.6rem;
  letter-spacing: -0.6px;
  border-radius: 4px;
  background-color: #000;
}
