.form-input__login.ant-input {
  font-size: 1.3rem;
  padding: 1.8rem 1.4rem;
  width: 334px;
  height: 56px;
  border-radius: 4px;
}

.form-input__password.ant-input {
  font-size: 1.3rem;
  padding: 1.8rem 1.4rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}
