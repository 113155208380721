.calendar-container {
  display: flex;

  .calendar.month-calendar {
    display: flex;
    width: 70%;
  }

  .today-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #fff;
  }
}

.today-header {
  display: flex;
  height: 56px; // antd header height
  color: #fff;

  .display--date {
    flex: 1;
    justify-content: start;
    background-color: #4b3dc9;
    padding-left: 2rem;
  }

  .display--button {
    width: 150px;
    background-color: #6b60ff;
    font-weight: bold;
  }
}

.cell-data-content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1rem;

  .cell-data {
    display: flex;
    width: 20px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin: 0px 2px;

    &.request {
      background-color: #a597f3;
    }

    &.confirm {
      background-color: hotpink;
    }
  }
}

.sunday {
  color: #f50;
}

.saturday {
  color: #108ee9;
}
