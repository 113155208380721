.change-password {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    height: 6rem;
    &--title {
      margin: 0;
      font-size: 1.6rem;
      font-weight: bold;
    }
    &--icon {
      cursor: pointer;
    }
  }

  &__content {
    padding: 2rem;
    &--validation {
      color: #dd4124;
      letter-spacing: -0.34px;
      font-size: 1.2rem;
      visibility: hidden;
      margin-bottom: 1.5rem;
      &.visible {
        visibility: visible;
      }
    }
  }
}
